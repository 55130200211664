import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static targets = [ "phone", "email", "phoneRadio" ]
  connect() {
    this.emailTarget.classList.add("hidden")
  }
  toggle() {
    console.log("radio!")
    this.phoneTarget.classList.toggle("hidden")
    this.emailTarget.classList.toggle("hidden")
  }
}