import { Controller } from "@hotwired/stimulus"
import Inputmask from "inputmask";

export default class extends Controller {
  static targets = ["input"];

  initialize() {
    // default placeholder
    var str = "AA:11:BB:22:CC:33";
    // apply Regex to the input mask, so you can only write times in the 24 hour format
    var im = new Inputmask({
      regex: "([0-9a-fA-F]{2}[-:]){5}([0-9a-fA-F]{2})",
      placeholder: str,
      clearMaskOnLostFocus: false,
    })
    im.mask(this.inputTarget);
  }

  getMac() {
    // get value from input on keyup and apply it to your simple form input
    var input = document.getElementById("arduino_device_mac_address").value.toUpperCase();

    // document.getElementById("arduino_device_mac_address").value = input;
    console.log("input: ", input);
  }
}